import { NestedLink } from '@/graphql/gql/graphql';
import Link from 'next/link';
import Image from '@/components/Image';
import { useRouter } from 'next/router';
import { NAV_LINK_CLICKED } from '@/constants/amplitude';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import { HTMLProps, useMemo, useState } from 'react';
import Styles from './MenuItem.module.scss';
import SportSubMenu from '../SportSubMenu';

interface MenuItemProps {
  menuLink: NestedLink,
  sport?: string,
  sportSlug: string | undefined,
}

const Container = (
  { children, ...attributes }: HTMLProps<HTMLSpanElement>,
) => <span {...attributes} className={Styles.noLinkContainer}>{children}</span>;
const MenuItem = ({
  menuLink,
  sport,
  sportSlug,
}: MenuItemProps) => {
  const {
    text, imageUrl, iconUrl, url,
  } = menuLink;
  const [isHover, setHover] = useState(false);
  const router = useRouter();
  const isSelectedSport = useMemo(
    () => url.toLowerCase() === `/${(router.query.slug?.toString() ?? sportSlug)?.toLowerCase()}` || text.toLocaleLowerCase() === (sport ?? '').toLocaleLowerCase(),
    [url, text, router.query.slug, sport, sportSlug],
  );
  const { logAmplitudeEvent } = useAmplitude();

  const MenuDisplay = url ? Link : Container;
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${Styles.hoverContain} ${isHover ? Styles.hoverItem : ''}`}
    >
      <MenuDisplay
        prefetch={url ? false : undefined}
        href={url || '/'}
        onClick={() => logAmplitudeEvent(
          NAV_LINK_CLICKED,
          {
            'Link Text': imageUrl ? 'fd_logo' : text,
            'Link URL': url,
            Module: 'navigation_menu',
            Menu: 'main_nav',
          },
        )}
      >
        <>
          {url ? (
            <span
              className={`${imageUrl ? Styles.imageContainer : Styles.container} ${isSelectedSport ? Styles.selected : ''}`}
            >
              <span>
                {imageUrl ? <Image style={{ display: 'inline' }} width={105} height={30} alt="menu item" src={imageUrl} />
                  : (
                    <>
                      <Image alt={`${text} icon`} src={iconUrl ?? ''} height={16} width={16} />
                      {text}
                    </>
                  )}
              </span>
            </span>
          ) : <span className={Styles.container}>{text}</span>}

        </>
      </MenuDisplay>
      {!imageUrl ? (
        <div
          className={`${Styles.submenu} ${isHover ? Styles.hoverMenu : ''}`}
        >
          <SportSubMenu hide={() => setHover(false)} submenu={menuLink} more={!menuLink.url} />
        </div>
      ) : ''}
    </div>
  );
};

export default MenuItem;
