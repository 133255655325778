import { ReactElement, useCallback } from 'react';
import 'twin.macro';
import { Button } from '@fanduel/og-fe-library';
import ErrorVan from '../svg/ErrorVan';

type ErrorComponentProps = {
  image?: React.ReactNode,
  headline?: string,
  subtext?: string | ReactElement,
  showCta?: boolean,
  ctaButtonLabel?: string,
  ctaButtonOnClick?: (arg?: any) => void,
};

const ErrorComponent = ({
  image = <ErrorVan />,
  headline = 'Content failed to load',
  subtext = 'Please check back later.',
  ctaButtonLabel = 'Refresh',
  ctaButtonOnClick,
  showCta = true,
}: ErrorComponentProps) => {
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <div data-testid="error-component" tw="flex flex-col items-center">
      {image}
      <div tw="mt-2 text-lg leading-5">
        {headline}
      </div>
      <div tw="mt-2 leading-5 text-grey-d1">
        {subtext}
      </div>
      <div tw="mt-4">
        {showCta && (
          <Button
            ariaLabelText={ctaButtonLabel}
            buttonText={ctaButtonLabel}
            buttonStyle="Outlined"
            rightIconName="icons.refresh"
            iconColor="colorBaseBlueDarken2"
            tw="flex flex-nowrap text-inherit"
            onClick={ctaButtonOnClick || reload}
          />
        )}
      </div>
    </div>
  );
};

export default ErrorComponent;
