import { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { FALLBACK_IMAGE } from '@/constants/media';

interface ImageFallBackProps extends Omit<ImageProps, 'src'> { fallback?: string, src: string | undefined | null }

const ImageWithFallback = ({
  fallback = FALLBACK_IMAGE,
  alt,
  src: srcProp,
  quality = 100,
  ...props
}: ImageFallBackProps) => {
  const [src, setSrc] = useState(srcProp);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (error) {
      setSrc(fallback);
    } else if (src !== srcProp)setSrc(srcProp);
  }, [srcProp, setSrc, src, error, fallback]);

  const baseSrc = src && src.length > 0 ? src : fallback;
  return (
    <Image
      alt={alt}
      src={baseSrc}
      onError={() => setError(true)}
      quality={quality}
      {...props}
    />
  );
};

export default ImageWithFallback;
