import { Maybe, GetSupportedSportsQuery } from '@/graphql/gql/graphql';
import logger from '@/utils/logger';

type HandlePromiseArgs<T> = {
  settled: PromiseSettledResult<T>,
  defaultValue: T,
  errorMessage?: string,
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

export function filterEmpty<T>(list: Maybe<T>[]) {
  return list.reduce((prev: T[], next: Maybe<T>) => {
    if (notEmpty(next)) {
      return [...prev, next];
    }
    return prev;
  }, [] as T[]);
}

export const findSportIfSportSlug = (
  supportedSports: GetSupportedSportsQuery['getSupportedSports'],
  pageSlugLowerCase: string,
) => {
  if (!supportedSports || supportedSports.length === 0) {
    return undefined;
  }
  return supportedSports.find((sport) => sport?.slug === pageSlugLowerCase);
};

export const handlePromise = <T>({
  settled,
  defaultValue,
  errorMessage,
}: HandlePromiseArgs<T>) => {
  if (settled.status === 'fulfilled') {
    return settled.value;
  }
  if (errorMessage) {
    logger.error(errorMessage, { error: settled.reason });
  }
  return defaultValue;
};

export const replaceLastSegment = (newSegment: string, excludedValues: string[] = []): void => {
  // Get the current URL
  const currentUrl: string = window.location.href;

  // Create a URL object
  const urlObject: URL = new URL(currentUrl);

  // Get the pathname and split it into segments
  const pathSegments: string[] = urlObject.pathname.split('/').filter((segment) => segment !== '');

  // Replace the last segment if it's not in the excluded values
  if (pathSegments.length > 0 && !excludedValues.includes(pathSegments[pathSegments.length - 1])) {
    pathSegments[pathSegments.length - 1] = newSegment;
  } else {
    pathSegments.push(newSegment);
  }

  // Update the pathname in the URL object
  urlObject.pathname = `/${pathSegments.join('/')}`;

  // Replace the current URL with the modified one
  window.history.replaceState({}, '', urlObject.href);
};
