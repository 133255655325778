import React from 'react';
import { HydrationBoundary, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalStyles from '@/components/Styles';
import DefaultLayout from '@/components/Layout';
import { LinkProvider } from '@fanduel/og-fe-library';
import { DefaultSeo } from 'next-seo';
import logger from '@/utils/logger';
import Link from 'next/link';
import Rum from '@/components/Rum';
import ErrorBoundary from '@/components/empty/ErrorBoundary';
import { AmplitudeProvider } from '@/utils/hooks/useAmplitude';
import getConfig from 'next/config';
import Script from 'next/script';
import { THEMES } from '@fanduel/formation-theming';
import { FormationProvider } from '@fanduel/formation-react-components';
import { SEO } from '../constants/index';
import '../styles/global.css';

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.API_MOCKING === 'enabled') {
  // eslint-disable-next-line global-require
  require('../mocks');
}

export default function MyApp({ Component, pageProps }: { Component: any, pageProps: any }) {
  const [queryClient] = React.useState(() => new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query.meta?.errorMessage) logger.error(query.meta, query.meta.errorMessage);
      },
    }),
    defaultOptions: {
      queries: {
        staleTime: 100000,
      },
    },
  }));

  const Layout = Component.getLayout || DefaultLayout;
  const titleTemplate = `%s | ${SEO.SITE_NAME}`;
  return (
    <>
      {publicRuntimeConfig.SEARCH_ENGINES ? (
        <Script data-testid="google-tag-manager" id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-N5T8BZG');`}
        </Script>
      ) : (
        <Script data-testid="google-tag-manager-dev" id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=oyyylYgsNvrsgOVavBH3Zw&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-N5T8BZG');`}
        </Script>
      )}
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <FormationProvider theme={THEMES.fanduel.light}>
            <GlobalStyles />
            <Rum />
            <AmplitudeProvider>
              <LinkProvider CustomLink={Link}>
                <Layout {...pageProps}>
                  <DefaultSeo
                    titleTemplate={titleTemplate}
                    dangerouslySetAllPagesToNoIndex={!publicRuntimeConfig.SEARCH_ENGINES}
                    dangerouslySetAllPagesToNoFollow={!publicRuntimeConfig.SEARCH_ENGINES}
                    openGraph={{
                      type: 'website',
                      locale: 'en_US',
                      url: SEO.FANDUEL_URL_RESEARCH,
                      siteName: SEO.SITE_NAME,
                    }}
                    twitter={{
                      handle: SEO.TWITTERHANDLE,
                      site: SEO.SITE_NAME,
                      cardType: 'summary_large_image',
                    }}
                  />
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Layout>
              </LinkProvider>
            </AmplitudeProvider>
          </FormationProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
          />
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  );
}
