import 'twin.macro';
import styled from 'styled-components';
import { SVGProps } from '@/types/svg';
import Svg from './Svg';
import { colorBlueSvg, colorGreyMid } from '../../styles/manualVars';

const Icon = styled(Svg) <SVGProps>` 
  color: white;
  display: inline;
  stroke: ${({ color = colorGreyMid }) => color};
  fill: ${({ color = colorGreyMid }) => color};
  margin: ${({ flat }) => (flat ? '6px 0 0 8px' : '-2px 0 0 8px')};
`;

function ArrowUp({ color, height = '17', width = '18', flat }: SVGProps) {
  return (
    <Icon data-testid="arrowup-svg" flat={flat} color={color} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.146501 5.353C0.341501 5.548 0.658501 5.548 0.853501 5.353L4.4995 1.707L8.1455 5.353C8.3405 5.548 8.6575 5.548 8.8525 5.353C9.0475 5.158 9.0475 4.841 8.8525 4.646L4.8525 0.646C4.7545 0.548 4.6265 0.5 4.4985 0.5C4.3705 0.5 4.2425 0.549 4.1445 0.646L0.144501 4.646C-0.048499 4.841 -0.048499 5.157 0.146501 5.353Z" fill={colorBlueSvg} />
    </Icon>
  );
}

export default ArrowUp;
