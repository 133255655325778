import { SVGProps } from '@/types/svg';
import Svg from './Svg';
import { colorGreyD4 } from '../../styles/manualVars';

const MobileMenu = ({ width = '40', height = '40' }: SVGProps) => (
  <Svg data-testid="mobile-menu" width={width} height={height}>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.1178 9.41177H5.88253C5.23311 9.41177 4.70605 9.93883 4.70605 10.5882C4.70605 11.2377 5.23311 11.7647 5.88253 11.7647H34.1178C34.7672 11.7647 35.2943 11.2377 35.2943 10.5882C35.2943 9.93883 34.7672 9.41177 34.1178 9.41177ZM5.88253 21.1765H22.3531C23.0025 21.1765 23.5296 20.6494 23.5296 20C23.5296 19.3506 23.0025 18.8235 22.3531 18.8235H5.88253C5.23311 18.8235 4.70605 19.3506 4.70605 20C4.70605 20.6494 5.23311 21.1765 5.88253 21.1765ZM5.88253 28.2353H31.7649C32.4143 28.2353 32.9413 28.7624 32.9413 29.4118C32.9413 30.0612 32.4143 30.5882 31.7649 30.5882H5.88253C5.23311 30.5882 4.70605 30.0612 4.70605 29.4118C4.70605 28.7624 5.23311 28.2353 5.88253 28.2353Z" fill={colorGreyD4} />
  </Svg>
);

export default MobileMenu;
