import 'twin.macro';
import styled from 'styled-components';
import { SVGProps } from '@/types/svg';
import Svg from './Svg';
import { colorBlueD1, colorGreyMid } from '../../styles/manualVars';

const Icon = styled(Svg) <SVGProps>` 
  color: white;
  display: inline;
  stroke: ${({ color = colorGreyMid }) => color};
  fill: ${({ color = colorGreyMid }) => color};
  margin: -2px 0 0 8px;
`;

const ArrowDown = ({ color }: SVGProps) => (
  <Icon data-testid="arrow-down" width="17" color={color} height="17" viewBox="0 0 17 17" fill="none">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
      <path id="icon" d="M12.8523 6.64625C12.6573 6.45125 12.3403 6.45125 12.1453 6.64625L8.49925 10.2922L4.85325 6.64625C4.65825 6.45125 4.34125 6.45125 4.14625 6.64625C3.95125 6.84125 3.95125 7.15825 4.14625 7.35325L8.14625 11.3533C8.24425 11.4513 8.37225 11.4992 8.50025 11.4992C8.62825 11.4992 8.75625 11.4503 8.85425 11.3533L12.8542 7.35325C13.0472 7.15825 13.0472 6.84225 12.8523 6.64625Z" fill={colorBlueD1} />
    </svg>
  </Icon>
);

export default ArrowDown;
