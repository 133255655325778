import { memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`

@font-face{
    font-display: fallback;
    src:url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Regular.woff') format('woff'),
    url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-family:"Proxima Nova"
}
@font-face{
    font-display: fallback;
    font-weight:bold;
    font-style:normal;
    src:url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Bold.woff2') format('woff2'),url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Bold.woff') format('woff'),
    url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Bold.ttf') format('truetype');
    font-family:"Proxima Nova"}
@font-face{
    font-display: fallback;
    src:url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Condensed.woff2') format('woff2'),
    url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Condensed.woff') format('woff'),
    url('https://iwa-assets.fanduel.com/fd-iwa-assets/fonts/ProximaNova-Condensed.ttf') format('truetype');
    font-family:"Proxima Nova Condensed"}

body {
    font-family: "Proxima Nova", sans-serif;
    ${tw`text-gray-dark`}
    ${tw`bg-gray-light`}
}
`;

function GlobalStyles() {
  return (
    <>
      <BaseStyles />
      <CustomStyles />
    </>
  );
}

export default memo(GlobalStyles);
