import getConfig from 'next/config';
import logger from '@/utils/logger';
import { useEffect } from 'react';

const Rum = () => {
  const { publicRuntimeConfig } = getConfig();

  const ddTraceEnabled = publicRuntimeConfig?.DD_TRACE_ENABLED === 'true';
  const ddAppId = publicRuntimeConfig?.DD_APP_ID ?? '';
  const ddAppToken = publicRuntimeConfig?.DD_APP_TOKEN ?? '';
  const ddEnv = publicRuntimeConfig?.DD_ENV ?? 'local';
  const ddService = publicRuntimeConfig?.DD_SERVICE ?? 'content-hub';
  const ddVersion = publicRuntimeConfig?.DD_VERSION ?? '0.0.0';
  useEffect(() => {
    async function injectDD() {
      const dataDogRum = await import('@datadog/browser-rum-slim').then(
        (module) => module.datadogRum,
      );
      dataDogRum.init({
        applicationId: ddAppId,
        clientToken: ddAppToken,
        site: 'datadoghq.com',
        service: ddService,
        env: ddEnv,
        sampleRate: 100,
        trackInteractions: true,
        version: ddVersion,
      });
    }
    if (ddTraceEnabled) {
      injectDD().catch((e) => logger.error(e));
    }
  }, [ddTraceEnabled, ddAppId, ddAppToken, ddEnv, ddService, ddVersion]);

  return null;
};

export default Rum;
