import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { getMenus } from '../api';
import { Maybe, Menu, NestedLink } from '../../graphql/gql/graphql';

type MenuResponse = {
  menuData: Record<string, Menu | undefined>,
  isError: boolean,
  isLoading: boolean,
  getSportMenu: (sport: string) => Maybe<NestedLink> | undefined,
};

const useMenus = (menus: string[]): MenuResponse => {
  const { data, isError, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['menus', menus],
    queryFn: () => getMenus(),
    select: (response) => response.getMenus || [],
  });

  const menuData = useMemo(() => (data ? menus.reduce(
    (menuCollection, menu) => {
      const test = data.find(
        (menuInfo) => menuInfo?.name?.toLowerCase() === menu.toLocaleLowerCase(),
      ) || undefined;

      return ({
        ...menuCollection,
        [menu]: test,
      });
    },
    {} as Record<string, Menu | undefined>,
  ) : {}), [data, menus]);

  const getSportMenu = useCallback(
    (sport: string) => menuData?.['Research Menu']?.menuItems.find(
      (menu) => menu?.text.toLowerCase() === sport?.toLowerCase(),
    ),
    [menuData],
  );

  return {
    menuData,
    isError,
    isLoading,
    getSportMenu,
  };
};

export const useNavMenus = () => useMenus(['header', 'Research Menu', 'footer', 'terms', 'copyright', 'Research Mobile']);

export default useMenus;
