import 'twin.macro';
import { Footer as BaseFooter, NestedLink } from '@fanduel/og-fe-library';
import { useNavMenus } from '@/utils/hooks/useMenus';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import { filterEmpty } from '@/utils/index';
import ampAppendSbkUrl from '@/utils/amplitude';
import { ResponsibleGaming } from './ResponsibleGaming';
import styles from './Footer.module.scss';

function Footer() {
  const { sessionId, deviceId } = useAmplitude();
  const { menuData } = useNavMenus();
  const footerMenuData = menuData.footer;
  const termsMenuData = menuData.terms && menuData.terms.menuItems;
  const partnersMenuData = menuData.copyright && menuData.copyright.menuItems;

  const footerMenu: NestedLink[] = footerMenuData?.menuItems
    ? filterEmpty(footerMenuData.menuItems).map((category) => {
      const children = filterEmpty(category?.children ?? []).map((link) => (
        { ...link, url: ampAppendSbkUrl(link?.url, sessionId, deviceId) }));
      return { ...category, children };
    })
    : [];

  return (
    <BaseFooter
      footerSitemapLinks={footerMenu.length > 0 ? footerMenu : undefined}
      footerSitemapClass={styles.footerSitemapLinks}
      keyLinks={termsMenuData}
      partnerLinks={partnersMenuData}
      rgComponent={<ResponsibleGaming />}
    />
  );
}

export default Footer;
