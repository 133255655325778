import { LinkBehaviour, NestedLink } from '@/graphql/gql/graphql';
import Link from 'next/link';
import Image from '@/components/Image';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import ampAppendSbkUrl from '@/utils/amplitude';
import { NAV_LINK_CLICKED } from '@/constants/amplitude';
import Styles from './SportSubMenu.module.scss';

interface SportSubMenuProps {
  submenu: NestedLink,
  more?: boolean,
  hide: () => void,
}

const SportSubMenu = ({ submenu, more, hide }: SportSubMenuProps) => {
  const { logAmplitudeEvent, deviceId, sessionId } = useAmplitude();
  return (
    <div className={Styles.container}>
      <div className={Styles.menu}>
        <p className={Styles.title}>
          {more ? submenu.text : `Explore ${submenu.text}`}
        </p>
        <ul className={more ? Styles.more : ''}>
          {submenu.children?.map((menuItem) => {
            const menuItemUrl = ampAppendSbkUrl(menuItem?.url, sessionId, deviceId);
            return (
              <li key={menuItem?.text}>
                <Link
                  target={menuItem?.behaviour && menuItem.behaviour === LinkBehaviour.NewWindow ? '_blank' : '_self'}
                  prefetch={false}
                  onClick={() => {
                    hide();
                    logAmplitudeEvent(
                      NAV_LINK_CLICKED,
                      {
                        'Link Text': menuItem?.text ?? '',
                        'Link URL': menuItemUrl,
                        Module: 'navigation_menu',
                        Menu: submenu.text,
                        ampDeviceId: deviceId ?? '',
                        ampSessionId: sessionId ?? '',

                      },
                    );
                  }}
                  href={menuItemUrl}
                >
                  <span>
                    {menuItem?.iconUrl
                      ? <Image width={16} height={16} src={menuItem.iconUrl} alt={`${menuItem.text} icon`} />
                      : ''}
                    {menuItem?.text}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default SportSubMenu;
