import { colorWhite } from '../../styles/manualVars';

const ErrorVan = () => (
  <svg
    version="1.1"
    id="Van"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="228.857px"
    height="183.026px"
    viewBox="0 0 228.857 183.026"
    enableBackground="new 0 0 228.857 183.026"
    xmlSpace="preserve"
  >
    <g>
      <polygon fill={colorWhite} points="137.314,67.822 177.703,67.822 187.872,111.034 136.401,111.034" />
      <path
        fill="#F4F6F9"
        d="M165.403,151.131H84.785l-19.576,0.634l-48.044,17.116c0,0,68.846-0.189,115.628-0.189 c23.389,0,41.266,0.047,42.261,0.189L165.403,151.131"
      />
      <polyline fill="#EBEBEB" points="174.952,152.105 174.952,152.105 173.444,153.604 174.952,152.105" />

      <rect x="108.508" y="21.398" transform="matrix(0.6787 -0.7344 0.7344 0.6787 19.9617 93.54)" fill="#CAD6E4" width="16.769" height="5.114" />
      <path
        fill="#CAD6E4"
        d="M126.563,22.552c2.045-2.213,1.692-5.864-0.788-8.157c-2.48-2.292-6.149-2.357-8.193-0.144l-0.062,0.071 l8.978,8.297L126.563,22.552z"
      />
      <path
        fill="#DAE4ED"
        d="M203.704,119.387l-11.549-7.698c-0.356-0.237-0.733-0.482-0.838-0.544c-0.105-0.062-0.27-0.453-0.368-0.87 l-10.484-44.588c-0.098-0.417-0.528-0.758-0.957-0.758h-19.073c-0.428,0-0.778,0-0.778,0s-0.35,0-0.778,0H71.39 c-0.428,0-1.129,0-1.557,0H30.781c-0.428,0-0.778,0.35-0.778,0.778v2.304c0,0.079,0.014,0.161,0.034,0.242v3.117 c-0.02,0.069-0.034,0.14-0.034,0.215v70.212c0,0.428,0,1.129,0,1.557v6.023c0,0.428,0.051,0.778,0.113,0.778 c0.062,0,0.463,0,0.891,0h18.847c0.428,0,0.778,0,0.778,0s0.051,0,0.113,0s0.463,0,0.891,0h38.826c0.428,0,1.129,0,1.557,0h83.528 c0.428,0,1.129,0,1.557,0h26.467c0.428,0,0.778-0.35,0.778-0.778V128.75c0-0.428,0-1.129,0-1.557v-6.596 C204.352,120.169,204.06,119.625,203.704,119.387z"
      />
      <path
        fill={colorWhite}
        d="M175.111,70.623c-0.101-0.4-0.522-0.727-0.934-0.727h-34.403c-0.413,0-0.75,0.338-0.75,0.75v38.063 c0,0.413,0.338,0.75,0.75,0.75h44.423c0.413,0,0.667-0.327,0.566-0.727L175.111,70.623z"
      />
      <path
        fill="#F0F0F0"
        d="M176.91,150.157c-0.425,0-0.994,0-1.362,0h-11.266l1.12,1.948h9.549L176.91,150.157 M203.563,150.157 h-26.458c-0.06,0-0.126,0-0.195,0l-1.959,1.948h28.561L203.563,150.157 M30.002,149.379v2.726h54.782l63.636-1.948h-56.4 c-0.428,0-1.129,0-1.557,0H51.637c-0.428,0-0.829,0-0.891,0s-0.113,0-0.113,0s-0.35,0-0.778,0H31.007c-0.428,0-0.829,0-0.891,0 S30.002,149.807,30.002,149.379 M203.742,119.414c0.291,0.214,0.532,0.629,0.594,1.004l0.016-0.599L203.742,119.414"
      />
      <path
        fill="#CEDAE6"
        d="M203.742,119.414l-12.615-8.38l-19.841,14.086H52.216l0.069,0.166H30.036v-0.166h-0.034v24.26 c0,0.428,0.051,0.778,0.113,0.778c0.062,0,0.463,0,0.891,0h18.847c0.428,0,0.778,0,0.778,0s0.051,0,0.113,0s0.463,0,0.891,0h38.826 c0.428,0,1.129,0,1.557,0h56.4h15.862h11.266c0.368,0,0.937,0,1.362,0c0.07,0,0.135,0,0.195,0h1.244h25.214h0.773v-29.74 C204.274,120.043,204.033,119.628,203.742,119.414z"
      />
      <polyline fill="#DDDDDD" points="176.91,150.157 176.91,150.157 174.952,152.105 176.91,150.157" />
      <polyline fill="#CEDAE6" points="30.036,71.703 30.036,125.119 30.036,125.285 52.285,125.285 52.216,125.119 30.036,71.703" />
      <polygon
        fill="#BCCCDD"
        points="30.002,146.156 30.002,152.105 84.785,152.105 165.403,152.105 209.693,152.105 209.693,146.156"
      />
      <polyline fill="#C3C8CE" points="204.305,123.433 204.258,123.433 204.17,126.806 204.305,126.806 204.305,123.433" />
      <rect x="73.322" y="57.648" fill="#CAD6E4" width="52.351" height="7.282" />
      <rect x="73.322" y="57.648" fill="#BCCCDD" width="12.855" height="7.282" />
      <path
        fill="#DAE4ED"
        d="M92.195,10.455c-9.19,9.943-7.604,26.355,3.543,36.656c11.146,10.302,27.632,10.592,36.821,0.649 c0.096-0.104,0.184-0.213,0.278-0.318L92.491,10.152C92.393,10.254,92.291,10.35,92.195,10.455z"
      />
      <path
        fill="#CAD6E4"
        d="M105.896,22.542L92.482,10.144c-0.098,0.102-0.199,0.198-0.296,0.302 c-9.19,9.943-7.604,26.355,3.543,36.656c3.366,3.111,7.219,5.302,11.211,6.581C99.409,42.716,103.324,28.99,105.896,22.542z"
      />
      <polygon fill="#306F98" points="200.512,135.225 200.512,135.225 200.512,135.225" />
      <polygon
        fill="#BCCCDD"
        points="200.512,135.225 200.512,135.225 200.132,135.225 189.308,143.258 190.659,148 191.829,152.105 209.693,152.105 209.693,135.225"
      />
      <circle fill="#BCCCDD" cx="175.052" cy="152.105" r="16.776" />
      <circle fill={colorWhite} cx="174.958" cy="152.187" r="9.989" />
      <path
        fill="#BCCCDD"
        d="M176.707,149.578l-3.633,3.612c-0.229,0.227-0.23,0.597-0.002,0.826c0.227,0.229,0.597,0.23,0.826,0.002 l3.633-3.612c0.229-0.227,0.23-0.597,0.002-0.826C177.306,149.352,176.936,149.351,176.707,149.578"
      />
      <circle fill="#BCCCDD" cx="54.958" cy="152.687" r="15.959" />
      <circle fill={colorWhite} cx="55.068" cy="152.809" r="9.989" />
      <path
        fill="#BCCCDD"
        d="M56.508,150.078l-3.633,3.612c-0.229,0.227-0.23,0.597-0.002,0.826c0.227,0.229,0.597,0.23,0.826,0.002 l3.633-3.612c0.229-0.227,0.23-0.597,0.002-0.826S56.737,149.85,56.508,150.078"
      />
      <path
        fill={colorWhite}
        d="M127.973,87.413c-0.007-0.152-0.081-0.284-0.194-0.37c-0.037-0.028-0.076-0.051-0.12-0.068 c-6.941-3.935-14.802-6.43-23.18-7.084c-0.033-0.007-0.066-0.01-0.101-0.01l-0.012,0.001c-0.145,0.004-0.272,0.07-0.36,0.172 l-0.045,0.059c-2.178,2.876-4.058,5.989-5.596,9.293l-0.001,0.003l-0.013,0.064c0,0.085,0.066,0.155,0.149,0.163l0.016,0.002 l0.018-0.001c0.476-0.017,0.944-0.027,1.414-0.028c6.294-0.014,12.296,1.232,17.768,3.501 c-1.576,12.515-8.163,23.474-17.692,30.799c-1.948-1.484-3.772-3.121-5.46-4.891l-0.02-0.021c-0.029-0.025-0.067-0.041-0.108-0.041 c-0.091,0-0.165,0.074-0.165,0.165l0,0.003l0.007,0.041c0.943,5.832,2.871,11.331,5.609,16.324l0.03,0.056 c0.054,0.077,0.145,0.128,0.247,0.128c0.037,0,0.072-0.007,0.104-0.02c0.033-0.012,0.063-0.031,0.09-0.053 c16.483-9.726,27.551-27.643,27.614-48.14l0.002-0.025L127.973,87.413z"
      />
      <path
        fill={colorWhite}
        d="M94.407,131.667c-2.241-5.438-3.627-11.319-3.99-17.476l0.002-0.022c0-0.034-0.004-0.067-0.01-0.099 c-0.012-0.056-0.034-0.108-0.063-0.156l-0.033-0.048c-1.108-1.559-2.123-3.189-3.033-4.884c0.895-0.427,1.817-0.809,2.758-1.147 c0.05-0.009,0.096-0.027,0.14-0.05c0.128-0.068,0.224-0.189,0.254-0.336c0.007-0.033,0.01-0.066,0.01-0.101l-0.002-0.021 c0.226-3.443,0.772-6.799,1.61-10.038c0.011-0.022,0.018-0.046,0.019-0.072l0-0.004c0-0.09-0.072-0.163-0.162-0.164l-0.003,0 l-0.069,0.015c-2.887,0.61-5.658,1.533-8.276,2.734c-0.609-2.144-1.068-4.35-1.362-6.609c3.762-1.579,7.778-2.673,11.97-3.206 l0.019,0.002c0.048,0,0.093-0.009,0.137-0.022c0.117-0.034,0.217-0.109,0.28-0.211c0.027-0.042,0.046-0.089,0.059-0.138 c1.448-3.395,3.232-6.613,5.31-9.613l0.043-0.062l0.011-0.06c0-0.089-0.072-0.161-0.16-0.164l-0.005-0.001l-0.004,0.001 c-10.084,0.035-19.534,2.732-27.694,7.42l-0.035,0.02c-0.124,0.086-0.206,0.228-0.212,0.388l-0.002,0.019l0.002,0.023 c0.137,18.087,8.834,34.126,22.234,44.28l0.038,0.029c0.024,0.013,0.051,0.021,0.08,0.021c0.091,0,0.165-0.074,0.165-0.165 l-0.005-0.037L94.407,131.667z"
      />
      <rect x="96.676" y="47.909" fill="#CAD6E4" width="5.644" height="11.685" />
      <polyline fill="#CAD6E4" points="56.309,71.541 40.351,71.541 40.351,74.914 56.309,74.914 56.309,71.541" />
      <rect x="197.231" y="123.433" fill="#BCCCDD" width="7.12" height="3.373" />
    </g>
  </svg>
);

export default ErrorVan;
