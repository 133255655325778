import { request } from 'graphql-request';
import getFeaturedArticles from '@/graphql/queries/getFeaturedArticles';
import getConfig from 'next/config';
import getSportsPN from '@/graphql/queries/getPlayerNewsSports';
import getRedirectQuery from '@/graphql/queries/getRedirectQuery';
import getHomepageQuery from '@/graphql/queries/getHomepageQuery';
import getArticlesQuery from '@/graphql/queries/getArticlesQuery';
import getArticleBySlugQuery from '@/graphql/queries/getArticleBySlugQuery';
import getAuthorsQuery from '@/graphql/queries/getAuthorsQuery';
import getMenusQuery from '@/graphql/queries/getMenus';
import getShortFormsQuery from '@/graphql/queries/getShortFormsQuery';
import getActiveFixturesQuery from '@/graphql/queries/getActiveFixturesQuery';
import {
  QueryGetSingleArticleArgs,
  ArticleSearchInput,
  PageType,
  QueryGetSupportedSportsArgs,
  AuthorSearchInput,
  ContentAuthorAuthoredContentArgs,
  QueryGetHomepageArgs,
  QueryGetProjectionTableMetadataArgs,
  QueryGetSlatesArgs,
  ProjectionsInput,
  ContentSportsEnum,
  PositionAbbrev,
  ShortFormNewsTypeEnum,
  ShortFormSearchInput,
} from '@/graphql/gql/graphql';
import getArticleInfoByDateRangeQuery from '@/graphql/queries/getArticleInfoByDateRangeQuery';
import getTentpoleEventQuery from '@/graphql/queries/getTentpoleEventQuery';
import getProjectionMetaDataQuery from '@/graphql/queries/getProjectionMetaDataQuery';
import getSlatesQuery from '@/graphql/queries/getSlatesQuery';
import getProjectionsQuery from '@/graphql/queries/getProjectionsQuery';
import getGolfEventsQuery from '@/graphql/queries/getGolfEventsQuery';
import getAllTentpoleEvents from '@/graphql/queries/getAllTentpoleEventsQuery';
import getShortFormQuery from '@/graphql/queries/getShortFormQuery';
import getSupportedSportsFilters from '@/graphql/queries/getSupportedSports/getSupportedSportsFilters';
import getSports from '@/graphql/queries/getSupportedSports/getSports';
import getHorseRacesQuery from '@/graphql/queries/getHorseRacesQuery';
import getRaceByTrackQuery from '@/graphql/queries/getRaceByTrackQuery';
import getTrackRacesQuery from '@/graphql/queries/getTrackRacesQuery';
import getPlayerNewsHomeQuery from '@/graphql/queries/getPlayerNewsHome';
import getHorseTrackListQuery from '@/graphql/queries/getHorseTrackListQuery';
import getRunnerStatsQuery from '@/graphql/queries/getRunnerStatsQuery';
import getHorseRacesByTrackQuery from '@/graphql/queries/getHorseRacesByTrackQuery';
import getTrackDetailsQuery from '@/graphql/queries/getTrackDetailsQuery';
import getSitemapShortFormsQuery from '@/graphql/queries/getSitemapShortFormsQuery';
import getContentFilters from '../graphql/queries/getContentFilters/getContentFilters';

export const getOrchestrationEndpoint = () => {
  const { publicRuntimeConfig } = getConfig() || {};
  const { ORCHESTRATION_ENDPOINT } = publicRuntimeConfig || {};
  return ORCHESTRATION_ENDPOINT || 'http://localhost:3000/api';
};

export function getMenus(isPreview?: boolean) {
  return request(
    getOrchestrationEndpoint(),
    getMenusQuery,
    { isPreview },
  );
}

export function getArticleBySlug({
  slug,
  contentStatus,
  isPreview,
}: QueryGetSingleArticleArgs) {
  return request(
    getOrchestrationEndpoint(),
    getArticleBySlugQuery,
    { slug, contentStatus, isPreview },
  );
}

export function getAllArticles({
  limit = 10,
  afterCursor = null,
  sportName = null,
  hideDeprioritizedContent = null,
}: ArticleSearchInput) {
  return request(
    getOrchestrationEndpoint(),
    getArticlesQuery,
    {
      filter: {
        limit,
        sportName,
        afterCursor,
        hideDeprioritizedContent,
      },
    },
  );
}

export function getArticleInfoByDateRange({
  publishedWithin,
  limit = 0,
}: ArticleSearchInput) {
  return request(
    getOrchestrationEndpoint(),
    getArticleInfoByDateRangeQuery,
    {
      filter: {
        publishedWithin,
        limit,
      },
    },
  );
}

export function getArticlesBySport(sports: string[]) {
  return Promise.all(sports.map((sportName) => request(
    getOrchestrationEndpoint(),
    getArticlesQuery,
    {
      filter: {
        sportName,
        afterCursor: '',
      },
    },
  )));
}

export function getFeaturedArticlesBySport(sportSlugs: string[]) {
  return Promise.all(sportSlugs.map((sportSlug) => request(
    getOrchestrationEndpoint(),
    getFeaturedArticles,
    {
      filter: {
        sportSlug,
        pageType: sportSlug ? PageType.Sport : PageType.Homepage,
      },
    },
  )));
}

interface GetShortFormsParams {
  filter: GetShortFormsParamsFilter,
  limit?: number,
}
interface GetShortFormsParamsFilter {
  start?: string,
  end?: string,
  sportName?: ContentSportsEnum,
  cursor?: string | null,
  teamNumberFireId?: number | null,
  positionAbbrev?: PositionAbbrev | null,
  shortFormNewsType?: ShortFormNewsTypeEnum | null,
}

export async function getShortForms({
  filter, limit }: GetShortFormsParams) {
  try {
    const { sportName, cursor, positionAbbrev, shortFormNewsType, teamNumberFireId } = filter;

    const queryParams = {
      filter: {
        limit,
        sport: sportName,
        afterCursor: cursor,
        team: {
          numberFireId: teamNumberFireId,
        },
        player: {
          positionAbbrev,
        },
        shortFormNewsType,
      },
    };

    const response = await request(
      getOrchestrationEndpoint(),
      getShortFormsQuery,
      queryParams,
    );

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in getShortForms API request:', error);
    throw error;
  }
}

export async function getSitemapShortForms({
  filter, limit }: GetShortFormsParams) {
  try {
    const { sportName,
      cursor, positionAbbrev,
      shortFormNewsType, teamNumberFireId, start, end } = filter;

    const publishedWithin = start && end ? {
      start,
      end,
    } : undefined;

    const queryFilter: ShortFormSearchInput = {
      limit,
      sport: sportName,
      afterCursor: cursor,
      team: {
        numberFireId: teamNumberFireId,
      },
      publishedWithin,
      player: {
        positionAbbrev,
      },
      shortFormNewsType,
    };
    const queryParams = {
      filter: queryFilter,
    };

    const response = await request(
      getOrchestrationEndpoint(),
      getSitemapShortFormsQuery,
      queryParams,
    );

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in getShortForms API request:', error);
    throw error;
  }
}
export function getAuthors({
  contentType,
  sportName,
  sport,
  afterCursor,
  limit = 12,
}: AuthorSearchInput & ContentAuthorAuthoredContentArgs) {
  return request(
    getOrchestrationEndpoint(),
    getAuthorsQuery,
    {
      input: { sportName, afterCursor, limit },
      contentType,
      sport,
    },
  );
}

export function getScores(sport?: string) {
  return request(
    getOrchestrationEndpoint(),
    getActiveFixturesQuery,
    { input: { sport } },
  );
}

export function getPlayerNewsHome({ sport, limit = 9 }: ShortFormSearchInput) {
  return request(
    getOrchestrationEndpoint(),
    getPlayerNewsHomeQuery,
    {
      filter: {
        sport,
        limit,
      },
    },
  );
}

export function getAvailableSports({ isPreview, featured }:
QueryGetSupportedSportsArgs = {}) {
  return request(
    getOrchestrationEndpoint(),
    getSports,
    { isPreview, featured },
  );
}

export function getAvailableSportsPN({ isPreview, featured }:
QueryGetSupportedSportsArgs = {}) {
  return request(
    getOrchestrationEndpoint(),
    getSportsPN,
    { isPreview, featured },
  );
}

export function getRedirect(slug: string) {
  return request(
    getOrchestrationEndpoint(),
    getRedirectQuery,
    { input: { slug } },
  );
}

export function getHomepage({ isPreview }: QueryGetHomepageArgs) {
  return request(
    getOrchestrationEndpoint(),
    getHomepageQuery,
    { isPreview },
  );
}

export function getTentpoleEvent(slug = '', sportSlug = '') {
  return request(
    getOrchestrationEndpoint(),
    getTentpoleEventQuery,
    { input: { slug, sportSlug } },
  );
}

export function getTentpoleEvents() {
  return request(
    getOrchestrationEndpoint(),
    getAllTentpoleEvents,
  );
}

export function getProjectionInfo(args: QueryGetProjectionTableMetadataArgs) {
  return request(
    getOrchestrationEndpoint(),
    getProjectionMetaDataQuery,
    args,
  );
}

export function getSlates(args: QueryGetSlatesArgs) {
  return request(
    getOrchestrationEndpoint(),
    getSlatesQuery,
    args,
  );
}

export function getGolfEvents() {
  return request(
    getOrchestrationEndpoint(),
    getGolfEventsQuery,
  );
}

export function getProjectionData(args: ProjectionsInput) {
  return request(
    getOrchestrationEndpoint(),
    getProjectionsQuery,
    { input: args },
  );
}

export function getShortForm(slug: string) {
  return request(
    getOrchestrationEndpoint(),
    getShortFormQuery,
    { slug },
  );
}

export function getSupportedSportsFiltersApi() {
  return request(
    getOrchestrationEndpoint(),
    getSupportedSportsFilters,
  );
}

export function getContentFiltersApi() {
  return request(
    getOrchestrationEndpoint(),
    getContentFilters,
  );
}

export function getUpcomingHorseRaces({ current = 0, results = 10 }) {
  return request(
    getOrchestrationEndpoint(),
    getHorseRacesQuery,
    { profile: 'FDR-Generic',
      current,
      results },
  );
}
interface GetRaceByTrackInput { trackCode: string, raceNumber: number }
export function getRaceByTrack({ trackCode, raceNumber }: GetRaceByTrackInput) {
  return request(
    getOrchestrationEndpoint(),
    getRaceByTrackQuery,
    { profile: 'FDR-Generic',
      trackCode,
      raceNumber },
  );
}

export function getTrackRaces({ trackCode }: { trackCode: string }) {
  return request(
    getOrchestrationEndpoint(),
    getTrackRacesQuery,
    { profile: 'FDR-Generic',
      trackCode },
  );
}

export function getHorseTrackList() {
  return request(
    getOrchestrationEndpoint(),
    getHorseTrackListQuery,
    { profile: 'FDR-Generic' },
  );
}

export function getRunnerStats({ runnerName }: { runnerName: string }) {
  return request(
    getOrchestrationEndpoint(),
    getRunnerStatsQuery,
    { profile: 'FDR-Generic',
      runnerName },
  );
}

export function getAllRacesByTrack({ trackCode }: Partial<GetRaceByTrackInput>) {
  return request(
    getOrchestrationEndpoint(),
    getHorseRacesByTrackQuery,
    { profile: 'FDR-Generic',
      trackCode,
    },
  );
}

export function getTrackDetails({ trackCode }: { trackCode: string }) {
  return request(
    getOrchestrationEndpoint(),
    getTrackDetailsQuery,
    { profile: 'FDR-Generic',
      trackCode,
    },
  );
}
