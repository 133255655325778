import { FOOTER_URLS, FOOTER_LABELS } from '@/constants/index';
import Link from '../Link';

type FooterTextProps = {
  gamblingHelpLineMALink: JSX.Element,
  fanduelRgLink: JSX.Element,
  ccpgLink: JSX.Element,
  kansasGamblingHelpLink: JSX.Element,
  mdgamblinghelpLink: JSX.Element,
  gamblerNetLink: JSX.Element,
};

export const footerText = ({
  gamblingHelpLineMALink,
  fanduelRgLink,
  ccpgLink,
  kansasGamblingHelpLink,
  mdgamblinghelpLink,
  gamblerNetLink,
}: FooterTextProps) => (
  <>
    {' '}
    Gambling Problem? Call 1-800-GAMBLER. Hope is here.
    {' '}
    {gamblingHelpLineMALink}
    {' '}
    or call (800)-327-5050 for 24/7 support (MA). Call 1-877-8HOPE-NY or Text HOPENY (467369) (NY).
    21+ in select states. FanDuel is offering online sports
    wagering in Kansas under an agreement with Kansas Star
    Casino, LLC. Gambling Problem? Call 1-800-GAMBLER or visit
    {' '}
    {fanduelRgLink}
    {' '}
    (CO, IA, KY, MI, NJ, OH, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ),
    1-888-789-7777 or visit
    {' '}
    {ccpgLink}
    {' '}
    (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit
    {' '}
    {kansasGamblingHelpLink}
    {' '}
    (KS), 1-877-770-STOP (LA), visit
    {' '}
    {mdgamblinghelpLink}
    {' '}
    (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit
    {' '}
    {gamblerNetLink}
    (WV).
    {' '}
  </>
);

export const linkCreator = ({ url, label }: { url: string, label: string }) => (
  <Link href={url} next={false}>
    {label}
  </Link>
);

export const ResponsibleGaming = () => {
  const fanduelRgLink = linkCreator({
    url: FOOTER_URLS.FANDUEL_RG_URL,
    label: FOOTER_LABELS.FANDUEL_RG_LABEL,
  });

  const ccpgLink = linkCreator({
    url: FOOTER_URLS.CCPG_URL,
    label: FOOTER_LABELS.CCPG_LABEL,
  });

  const gamblerNetLink = linkCreator({
    url: FOOTER_URLS.GAMBLER_NET_URL,
    label: FOOTER_LABELS.GAMBLER_NET_LABEL,
  });

  const gamblingHelpLineMALink = linkCreator({
    url: FOOTER_URLS.GAMBLING_HELPLINE_MA_URL,
    label: FOOTER_LABELS.GAMBLING_HELPLINE_MA_LABEL,
  });

  const kansasGamblingHelpLink = linkCreator({
    url: FOOTER_URLS.KANSAS_GAMBLING_HELP_URL,
    label: FOOTER_LABELS.KANSAS_GAMBLING_HELP_LABEL,
  });

  const mdgamblinghelpLink = linkCreator({
    url: FOOTER_URLS.MD_GAMBLING_HELP_URL,
    label: FOOTER_LABELS.MD_GAMBLING_HELP_LABEL,
  });

  return (
    <div>
      {footerText({
        fanduelRgLink,
        ccpgLink,
        gamblerNetLink,
        gamblingHelpLineMALink,
        kansasGamblingHelpLink,
        mdgamblinghelpLink,
      })}
    </div>
  );
};
